/*
Importing all the necessary files for the VITE project that no are React
*/
import $ from 'jquery';
// window.$ = $; // Optional: Make jQuery globally available
window.$ = window.jQuery = $;


import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;
